
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import t from "@common/src/i18n/t";
const detecationTemplate = namespace("detecationTemplate");
const detecationItem = namespace("detecationItem");
const app = namespace("app");
@Component({})
export default class TestTemplateEdit extends Vue {
  @detecationTemplate.Action saveDetecationTemplate;
  @detecationTemplate.Action getDetecationTemplateDetail;
  @detecationItem.Action getAllItemCodeNamList;
  @detecationItem.Action maintainCalendarProjectPage;

  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  get breadData() {
    return [
      { name: "养车日历模板", path: "/maintenance/detecationTemplate/list" },
      { name: this.isAdd ? "创建模板" : "修改模板" }
    ];
  }

  templateTypeOptions: any = [
    {label: "燃油版", value: 1}, {label: "新能源版", value: 2}
  ]
  get isAdd() {
    return this.$route.name === "detecationTemplateAdd";
  }
  get templateId() {
    return this.$route.params.templateId;
  }
  mainInfoRules: any = {
    templateName: [
      {
        required: true,
        message: "请输入模板名称",
        trigger: "blur"
      }
    ],
    templateType: [
      {
        required: true,
        message: "请选择类型",
        trigger: "blur"
      }
    ]
  };
  mainInfoFormData: any = {
    templateName: "",
    remark: "",
    templateType: "",
    calendarType: ""
  };
  definitionNewItem: any = {
    projectCode: "",
    optionRuleType: "",
    sort: ""
  };

  newItemList: any = [];
  maintainProjectList: any = [];
  itemsList: any = [];
  created() {
    this.init();
  }
  saveBeforeItem: any = [];
  async init() {
    // this.searchTemplateMethod("");
    await this.getItemMethod();
    if (!this.isAdd) {
      this.getDetecationTemplateDetail({
        templateId: this.templateId
      }).then(data => {
        Object.assign(this.mainInfoFormData, data.data);
        this.newItemList = data.data.templateItems;
        console.log("newItemList", this.newItemList)
      });
    }
    //this.handleAddTestItem();
  }

  // selectedCopyTemplateCode: string = "";
  // handleOpenSelectTemplate() {
  //   this.showSelectTemplate = true;
  // }
  getItemMethod() {
    this.getAllItemCodeNamList({}).then(data => {
      this.itemsList = data.data;
    });
    this.maintainCalendarProjectPage({
      current: 1,
      size: 500
    }).then(data => {
      this.maintainProjectList = data.data.list;
      return data;
    });
  }
  handleSaveTemplate() {
    (this.$refs.mainInfoForm as any).validate(valid => {
      if (valid && this.checkDataValid()) {
        let params = Object.assign({}, this.mainInfoFormData, {
          templateItems: this.newItemList.map((item, index) => {
            item.sort = index + 1;
            return item;
          })
        });
        console.log("保存数据：", params);
        this.btnStartLoading();
        this.saveDetecationTemplate(params)
          .then(data => {
            this.$message.success(t("base.save-success"));
            this.$router.push(`/maintenance/detecationTemplate/list`);
          })
          .finally(() => {
            this.btnStopLoading();
          });
      }
    });
  }
  checkDataValid() {
    if (this.newItemList.length < 1) {
      this.$message.error("必须添加一个养车日历项目");
      return false;
    }
    let itemCodeIsNull = this.newItemList.some(item => {
      return !item.projectCode;
    });
    if (itemCodeIsNull) {
      this.$message.error("请选择项目");
      return false;
    }
    return true;
  }
  // testTemplateList: any = [];
  // searchTemplateMethod(val?) {
  //   this.getTestingTemplateList({
  //     pageNum: 1,
  //     pageSize: 10,
  //     status: "9",
  //     checkTemplateName: val
  //   }).then(data => {
  //     this.testTemplateList = data.data.list;
  //   });
  // }
  // handleSetingEnd() {
  //   this.getTestingTemplateDetail({
  //     checkTemplateCode: this.selectedCopyTemplateCode
  //   }).then(data => {
  //     this.newTestItemList = data.data.checkItems;
  //   });
  //   this.showSelectTemplate = false;
  // }
  handleAddTestItem() {
    this.newItemList.push(Object.assign({}, this.definitionNewItem));
  }
  handleItemDown(idx) {
    if (idx >= this.newItemList.length - 1) return;
    let tempRow = this.newItemList[idx];
    this.newItemList.splice(idx, 1, this.newItemList[idx + 1]);
    this.newItemList.splice(idx + 1, 1, tempRow);
  }
  handleItemUp(idx) {
    if (idx <= 0) return;
    let tempRow = this.newItemList[idx];
    this.newItemList.splice(idx, 1, this.newItemList[idx - 1]);
    this.newItemList.splice(idx - 1, 1, tempRow);
  }
  handleInsertRow(index) {
    this.newItemList.splice(index + 1, 0, Object.assign({}, this.definitionNewItem), {optionRuleType: 1});
  }
  handleDeleteRow(index) {
    this.newItemList.splice(index, 1);
  }
}
